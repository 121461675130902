import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import HTMLContent from "../components/HTMLContent";
import PageBanner from "../components/PageBanner";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AboutPageTemplate = ({
  description,
  banner,
  team,
  history,
  HistoryDescription,
  newsletterSection,
  employmentSection,
  EmploymentDescription,
}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogInputs, setDialogInputs] = React.useState(null);
  function handleClose() {
    setDialogOpen(false);
  }
  function handleExited() {
    setDialogInputs(null);
  }
  return (
    <>
      <PageBanner>
        <h1 className="page-banner-header">ABOUT US</h1>
        <p className="width-container">{description}</p>
      </PageBanner>
      <div className="layout-container">
        <div className="grid-container top-image-container row-reverse-section">
          <div className="grid-item-55 angled-image body-bg-image">
            <PreviewCompatibleImage
              imageData={banner.image}
              gatsbyImageProps={{ loading: "eager" }}
            />
          </div>
          <div className="grid-item-45 angled-content">
            <h3>{banner.heading}</h3>
            <p>{banner.description}</p>
            <div className="svg-container angled">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                preserveAspectRatio="none"
                viewBox="0 0 100 100"
                width="100%"
                height="100%"
              >
                <path
                  fill="currentColor"
                  d="M0 100C0 100 0 100 0 100C22.81 57.62 57.56 22.86 99.94 0.03C99.94 0.03 99.97 0.02 100 0L0 0L0 100Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <section className="text-center body-id-section" id="meet-the-team">
          <div className="landing-header-container">
            <h3>Meet The Team</h3>
          </div>
          <div className="grid-container add-width">
            {team.map((member) => {
              const { name, title, image } = member;
              return (
                <div className="grid-item grid-item-3" key={name}>
                  <button
                    className="landing-paper-container"
                    onClick={() => {
                      setDialogInputs(member);
                      setDialogOpen(true);
                    }}
                  >
                    <div className="paper-body about-us-info">
                      <div className="img-container">
                        <PreviewCompatibleImage
                          imageData={image}
                          alt={name}
                          gatsbyImageProps={{ fadeIn: false }}
                          aspectRatio={1}
                        />
                      </div>
                      <h4 className="name link-color">{name}</h4>
                      <p className="title">{title}</p>
                    </div>
                    <div className="paper-background" />
                  </button>
                </div>
              );
            })}
          </div>
        </section>
        <section className="body-id-section" id="board-and-history">
          <div className="grid-container add-width">
            <div className="grid-item grid-item-2 board-members">
              <h4>Board of Directors</h4>
              <ul>
                {history.boardMembers.map(({ title, info }, index) => {
                  return (
                    <li key={`board-${index}`}>
                      <p>
                        <strong>{title}</strong>
                        <br />
                        <span className="info">{info}</span>
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="grid-item grid-item-2">
              <div className="body-img">
                <PreviewCompatibleImage imageData={history.image} />
              </div>
              <h4>School History</h4>
              <div>{HistoryDescription}</div>
            </div>
          </div>
        </section>
        <section className="body-id-section" id="newsletters">
          <div className="width-container text-center">
            <div className="landing-header-container">
              <h3>School Newsletters</h3>
            </div>
            <p>{newsletterSection.description}</p>
            <div style={{ marginBottom: 16 }}>
              <a
                href="https://philips-academy-flipbook.netlify.app/"
                className="btn primary-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                FALL 2023 NEWSLETTER FLIPBOOK
              </a>
            </div>
          </div>
          {newsletterSection.newsletters &&
            newsletterSection.newsletters.length > 0 && (
              <div className="grid-container add-width">
                {newsletterSection.newsletters.map(
                  ({ image, title, pdf, linkUrl, description }, index) => {
                    return (
                      <div
                        className="grid-item grid-item-3"
                        key={`newsletter-${index}`}
                      >
                        <a
                          className="landing-paper-container"
                          href={linkUrl || pdf.publicURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="paper-body">
                            <div className="newsletter-container">
                              <div>
                                <div className="image-container">
                                  <PreviewCompatibleImage
                                    imageData={image}
                                    alt="Newsletter thumbnail"
                                  />
                                </div>
                                <div className="newsletter-body">
                                  <h5>{title}</h5>
                                  <p className="description">{description}</p>
                                </div>
                              </div>
                              <div>
                                <div className="learn-more-grid">
                                  <div>
                                    <span />
                                  </div>
                                  <div>
                                    <p>READ MORE</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="paper-background" />
                        </a>
                      </div>
                    );
                  }
                )}
              </div>
            )}
        </section>
        <section className="body-id-section" id="employment-opportunities">
          <div className="landing-header-container text-center">
            <h3>Employment Opportunities</h3>
          </div>
          <div className="grid-container add-width job-section">
            <div className="grid-item grid-item-2">
              <div className="text-left width-container">
                {EmploymentDescription}
              </div>
              {employmentSection.jobPostings &&
              employmentSection.jobPostings.length > 0 ? (
                <>
                  <h4 className="padding-top-2">Current Job Openings</h4>
                  {employmentSection.jobPostings.map(
                    ({ title, description, pdf }, index) => {
                      if (pdf && title && description) {
                        return (
                          <a
                            key={`support-link-${index}`}
                            className="job-container"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={pdf.publicURL}
                          >
                            <h5>{title}</h5>
                            <p>{description}</p>
                            <div className="learn-more-grid">
                              <div>
                                <span />
                              </div>
                              <div>
                                <p>LEARN MORE</p>
                              </div>
                            </div>
                          </a>
                        );
                      }
                      return <></>;
                    }
                  )}
                </>
              ) : (
                <div className="btn-container">
                  <Link
                    to="/contact/"
                    className="btn primary-btn full-width-sm"
                  >
                    CONTACT US FOR MORE INFO
                  </Link>
                </div>
              )}
              <div className="text-left width-container">
                <p>
                  <em>{employmentSection.legalDisclaimer}</em>
                </p>
              </div>
            </div>
            <div className="grid-item grid-item-2">
              <div className="body-img">
                <PreviewCompatibleImage imageData={employmentSection.image} />
              </div>
            </div>
          </div>
        </section>
        <Dialog
          open={dialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          onExited={handleExited}
          fullWidth
          PaperProps={{
            style: {
              maxWidth: 800,
              marginLeft: 16,
              marginRight: 16,
              width: "100%",
            },
          }}
        >
          <div className="add-padding">
            <button className="btn close-btn" onClick={handleClose}>
              &times;
            </button>
            {dialogInputs && (
              <>
                <div className="grid-container add-width">
                  <div className="grid-item grid-item-3 text-center">
                    <div className="staff-img">
                      <PreviewCompatibleImage
                        imageData={dialogInputs.image}
                        alt={dialogInputs.name}
                        gatsbyImageProps={{ fadeIn: false }}
                      />
                    </div>
                  </div>
                  <div className="grid-item grid-item-2-3 about-us-info">
                    <h4 className="name big">{dialogInputs.name}</h4>
                    <p className="title">{dialogInputs.title}</p>
                    {dialogInputs.email && (
                      <a href={`mailto:${dialogInputs.email}`}>
                        {dialogInputs.email}
                      </a>
                    )}
                    <p className="description">{dialogInputs.description}</p>
                  </div>
                </div>
                <div className="close-btn-container">
                  <button className="btn white-btn small" onClick={handleClose}>
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </Dialog>
      </div>
    </>
  );
};

AboutPageTemplate.propTypes = {
  description: PropTypes.string,
  banner: PropTypes.object,
  team: PropTypes.array,
  history: PropTypes.object,
  newsletterSection: PropTypes.object,
  employmentSection: PropTypes.object,
  EmploymentDescription: PropTypes.node,
};

const AboutPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        description,
        banner,
        team,
        history,
        newsletterSection,
        employmentSection,
      },
    },
  } = data;
  return (
    <Layout pageTitle={`About Us`} ogUrl="/about/" pageDecription={description}>
      <AboutPageTemplate
        description={description}
        banner={banner}
        team={team}
        history={history}
        HistoryDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML.history
                .markdown
            }
          />
        }
        newsletterSection={newsletterSection}
        employmentSection={employmentSection}
        EmploymentDescription={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML
                .employmentSection.markdown
            }
          />
        }
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          description
          heading
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        team {
          description
          email
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          title
        }
        history {
          boardMembers {
            title
            info
          }
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        employmentSection {
          legalDisclaimer
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          jobPostings {
            title
            description
            pdf {
              publicURL
            }
          }
        }
        newsletterSection {
          description
          newsletters {
            image {
              childImageSharp {
                fluid(maxWidth: 450, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
            pdf {
              publicURL
            }
            linkUrl
          }
        }
      }
      fields {
        frontmatterMarkdownHTML {
          history {
            markdown
          }
          employmentSection {
            markdown
          }
        }
      }
    }
  }
`;
